import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { apiRequest } from "../apiservice.js";
import "./auth.css";

const Register = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    confirmPassword: "",
    userType: "client",
    // Vendor profile fields
    companyName: "",
    businessDescription: "",
    contactPhone: "",
    website: "",
    // Client profile fields
    firstName: "",
    lastName: "",
    preferences: "",
  });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    if (formData.password !== formData.confirmPassword) {
      setError("Passwords do not match");
      setLoading(false);
      return;
    }

    const requestData = {
      username: formData.username,
      password: formData.password,
      user_type: formData.userType,
    };

    if (formData.userType === "vendor") {
      requestData.vendor_profile = {
        company_name: formData.companyName,
        business_description: formData.businessDescription,
        contact_phone: formData.contactPhone,
        website: formData.website,
      };
    } else {
      requestData.client_profile = {
        first_name: formData.firstName,
        last_name: formData.lastName,
        preferences: formData.preferences,
      };
    }

    try {
      await apiRequest("register", "POST", requestData);
      setLoading(false);
      navigate("/login");
    } catch (err) {
      console.error("Registration error:", err);
      setError(err.message || "Network error. Please try again.");
      setLoading(false);
    }
  };

  return (
    <div className="auth-container">
      <div className="auth-wrapper">
        <h2>Create an Account</h2>
        {error && <div className="auth-error">{error}</div>}
        <form onSubmit={handleSubmit} className="auth-form">
          <div className="form-group">
            <input
              type="text"
              name="username"
              value={formData.username}
              onChange={handleChange}
              placeholder="Username"
              required
            />
          </div>
          <div className="form-group">
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              placeholder="Password"
              required
            />
          </div>
          <div className="form-group">
            <input
              type="password"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
              placeholder="Confirm Password"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="userType">Account Type:</label>
            <select
              id="userType"
              name="userType"
              value={formData.userType}
              onChange={handleChange}
              required
            >
              <option value="client">Client</option>
              <option value="vendor">Vendor</option>
            </select>
          </div>

          {/* Conditional fields based on user type */}
          {formData.userType === "vendor" ? (
            <>
              <h3>Vendor Information</h3>
              <div className="form-group">
                <input
                  type="text"
                  name="companyName"
                  value={formData.companyName}
                  onChange={handleChange}
                  placeholder="Company Name"
                />
              </div>
              <div className="form-group">
                <textarea
                  name="businessDescription"
                  value={formData.businessDescription}
                  onChange={handleChange}
                  placeholder="Business Description"
                  rows="3"
                ></textarea>
              </div>
              <div className="form-group">
                <input
                  type="tel"
                  name="contactPhone"
                  value={formData.contactPhone}
                  onChange={handleChange}
                  placeholder="Contact Phone"
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="website"
                  value={formData.website}
                  onChange={handleChange}
                  placeholder="Website"
                />
              </div>
            </>
          ) : (
            <>
              <h3>Client Information</h3>
              <div className="form-group">
                <input
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  placeholder="First Name"
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  placeholder="Last Name"
                />
              </div>
              <div className="form-group">
                <textarea
                  name="preferences"
                  value={formData.preferences}
                  onChange={handleChange}
                  placeholder="Preferences"
                  rows="3"
                ></textarea>
              </div>
            </>
          )}

          <button type="submit" className="auth-button" disabled={loading}>
            {loading ? "Registering..." : "Register"}
          </button>
        </form>
        <p className="auth-link">
          Already have an account? <Link to="/login">Login here</Link>
        </p>
      </div>
    </div>
  );
};

export default Register;
