import React, { useState, useEffect, useCallback } from "react";
import { countriesByRegion, regions } from "../countryselector/countryselector";
import { apiRequest } from "../apiservice.js";
import ServiceFilter from "./servicefilter";
import "./vendors.css";

const Vendors = () => {
  const [allListings, setAllListings] = useState([]);
  const [listings, setListings] = useState([]);
  const [filteredListings, setFilteredListings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [viewingCountry, setViewingCountry] = useState(false);
  const [organizedCountries, setOrganizedCountries] = useState({});
  const [filteredCountries, setFilteredCountries] = useState({});
  const [serviceFilter, setServiceFilter] = useState({
    services: [],
    matchingMode: "OR",
  });

  const apiBaseUrl = process.env.REACT_APP_API_URL;

  const organizeCountriesByRegion = useCallback((countries) => {
    console.log("Organizing countries:", countries);
    const organized = {};

    regions.forEach((region) => {
      const countriesInRegion = countriesByRegion[region].filter((country) =>
        countries.includes(country)
      );

      if (countriesInRegion.length > 0) {
        organized[region] = countriesInRegion;
      }
    });

    console.log("Organized countries:", organized);
    setOrganizedCountries(organized);
    setFilteredCountries(organized);
  }, []);

  const fetchAllListings = useCallback(async () => {
    try {
      setLoading(true);
      let endpoint = `${apiBaseUrl}/api.php?action=showVendors`;

      console.log("Fetching all vendors from:", endpoint);
      const response = await fetch(endpoint);
      const data = await response.json();

      if (response.ok) {
        setAllListings(data.listings || []);
      } else {
        setError(data.error || "Failed to fetch all listings");
      }
    } catch (err) {
      console.error("Error fetching all listings:", err);
      setError("Error fetching all listings. Please try again.");
    } finally {
      setLoading(false);
    }
  }, [apiBaseUrl]);

  const fetchCountries = useCallback(async () => {
    try {
      const data = await apiRequest("getAvailableCountries", "GET");
      organizeCountriesByRegion(data.countries || []);
    } catch (err) {
      console.error("Error fetching countries:", err);
    }
  }, [organizeCountriesByRegion]);

  const fetchListings = useCallback(async () => {
    if (!selectedCountry) return;

    try {
      setLoading(true);

      let endpoint = `${apiBaseUrl}/api.php?action=showVendors`;
      endpoint += `&country=${encodeURIComponent(selectedCountry)}`;

      console.log("Fetching vendors from:", endpoint);
      const response = await fetch(endpoint);
      const data = await response.json();
      console.log("Vendor listings data:", data);

      if (response.ok) {
        setListings(data.listings || []);
        setFilteredListings(data.listings || []);
      } else {
        setError(data.error || "Failed to fetch listings");
      }
    } catch (err) {
      console.error("Error fetching listings:", err);
      setError("Error fetching listings. Please try again.");
    } finally {
      setLoading(false);
    }
  }, [selectedCountry, apiBaseUrl]);

  useEffect(() => {
    if (
      Object.keys(organizedCountries).length === 0 ||
      allListings.length === 0
    ) {
      return;
    }

    if (serviceFilter.services.length === 0) {
      setFilteredCountries(organizedCountries);
      return;
    }

    const countriesWithMatchingServices = new Set();

    allListings.forEach((listing) => {
      const vendorServices = Array.isArray(listing.vendor_services)
        ? listing.vendor_services
        : listing.vendor_services
        ? [listing.vendor_services]
        : [];

      let matches = false;

      if (serviceFilter.matchingMode === "OR") {
        matches = serviceFilter.services.some((service) =>
          vendorServices.includes(service)
        );
      } else {
        matches = serviceFilter.services.every((service) =>
          vendorServices.includes(service)
        );
      }

      if (matches) {
        countriesWithMatchingServices.add(listing.country_of_operation);
      }
    });

    const filteredOrganized = {};

    Object.entries(organizedCountries).forEach(([region, countries]) => {
      const matchingCountries = countries.filter((country) =>
        countriesWithMatchingServices.has(country)
      );

      if (matchingCountries.length > 0) {
        filteredOrganized[region] = matchingCountries;
      }
    });

    setFilteredCountries(filteredOrganized);
  }, [organizedCountries, allListings, serviceFilter]);

  useEffect(() => {
    if (listings.length === 0) {
      setFilteredListings([]);
      return;
    }

    if (serviceFilter.services.length === 0) {
      setFilteredListings(listings);
      return;
    }

    const filtered = listings.filter((listing) => {
      const vendorServices = Array.isArray(listing.vendor_services)
        ? listing.vendor_services
        : listing.vendor_services
        ? [listing.vendor_services]
        : [];

      if (serviceFilter.matchingMode === "OR") {
        return serviceFilter.services.some((service) =>
          vendorServices.includes(service)
        );
      } else {
        return serviceFilter.services.every((service) =>
          vendorServices.includes(service)
        );
      }
    });

    setFilteredListings(filtered);
  }, [listings, serviceFilter]);

  const handleFilterChange = useCallback((newFilter) => {
    setServiceFilter(newFilter);
  }, []);

  useEffect(() => {
    fetchAllListings();

    fetchCountries();
  }, [fetchAllListings, fetchCountries]);

  useEffect(() => {
    if (selectedCountry) {
      fetchListings();
    }
  }, [selectedCountry, fetchListings]);

  const viewCountry = (country) => {
    console.log("Viewing country:", country);
    setSelectedCountry(country);
    setViewingCountry(true);
  };

  const returnToVendors = () => {
    setSelectedCountry("");
    setViewingCountry(false);
  };

  const ListingItem = ({ listing }) => {
    return (
      <div className="vendors-listing-item">
        <div className="vendors-listing-header">
          <h3>{listing.company_name}</h3>
          <span className="vendors-country-badge">
            {listing.country_of_operation}
          </span>
        </div>

        <div className="vendors-listing-services">
          {Array.isArray(listing.vendor_services) ? (
            listing.vendor_services.map((service) => (
              <span key={service} className="vendors-service-badge">
                {service}
              </span>
            ))
          ) : (
            <span className="vendors-service-badge">
              {listing.vendor_services}
            </span>
          )}
        </div>

        <div className="vendors-listing-details">
          {listing.website && (
            <p>
              <strong>Website:</strong> {listing.website}
            </p>
          )}
          {listing.phone && (
            <p>
              <strong>Phone:</strong> {listing.phone}
            </p>
          )}
          {listing.email && (
            <p>
              <strong>Email:</strong> {listing.email}
            </p>
          )}
          {listing.address1 && (
            <p>
              <strong>Address:</strong> {listing.address1}
            </p>
          )}
          {listing.address2 && <p>{listing.address2}</p>}
          {listing.description && (
            <p className="vendors-listing-description">{listing.description}</p>
          )}
        </div>
      </div>
    );
  };

  const CountryCard = ({ country }) => {
    return (
      <div
        className="vendors-country-card"
        onClick={() => viewCountry(country)}
      >
        <h3>{country}</h3>
      </div>
    );
  };

  const RegionSection = ({ region, countries }) => {
    return (
      <div className="vendors-region-section">
        <h2 className="vendors-region-title">{region}</h2>
        <div className="vendors-countries-grid">
          {countries.map((country) => (
            <CountryCard key={country} country={country} />
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="vendors-container">
      <h1 className="vendors-heading">Global Security Providers</h1>

      <ServiceFilter onFilterChange={handleFilterChange} />

      {error && <div className="vendors-error-message">{error}</div>}

      {viewingCountry ? (
        <div className="vendors-country-listings">
          <div className="vendors-country-header">
            <h2>Security Providers in {selectedCountry}</h2>
            <button className="vendors-return-button" onClick={returnToVendors}>
              Return to Countries List
            </button>
          </div>

          {loading ? (
            <p>Loading listings...</p>
          ) : filteredListings.length > 0 ? (
            <div className="vendors-listings-grid">
              {filteredListings.map((listing) => (
                <ListingItem key={listing.id} listing={listing} />
              ))}
            </div>
          ) : (
            <p className="vendors-no-listings">
              {serviceFilter.services.length > 0
                ? `No security providers in ${selectedCountry} match your selected filters.`
                : `No listings found for ${selectedCountry}.`}
            </p>
          )}
        </div>
      ) : (
        <div className="vendors-regions-container">
          <h2>Select a Country</h2>
          {loading && Object.keys(filteredCountries).length === 0 ? (
            <p>Loading countries...</p>
          ) : Object.keys(filteredCountries).length > 0 ? (
            <div className="vendors-regions-list">
              {Object.entries(filteredCountries).map(([region, countries]) => (
                <RegionSection
                  key={region}
                  region={region}
                  countries={countries}
                />
              ))}
            </div>
          ) : (
            <p className="vendors-no-countries">
              {serviceFilter.services.length > 0
                ? "No countries have security providers matching your selected filters."
                : "No countries with security providers found."}
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default Vendors;
