import React, { createContext, useState, useContext, useEffect } from "react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [userId, setUserId] = useState(null);
  const [userType, setUserType] = useState(null);
  const [userProfile, setUserProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    const storedUserId = localStorage.getItem("userId");
    const storedUsername = localStorage.getItem("username");
    const storedUserType = localStorage.getItem("userType");
    const storedUserProfile = localStorage.getItem("userProfile");

    if (token && storedUserId && storedUsername) {
      setIsLoggedIn(true);
      setUserEmail(storedUsername);
      setUserId(storedUserId);

      if (storedUserType) {
        setUserType(storedUserType);
      }

      if (storedUserProfile) {
        try {
          setUserProfile(JSON.parse(storedUserProfile));
        } catch (e) {
          console.error("Error parsing user profile:", e);
        }
      }
    }

    setIsLoading(false);
  }, []);

  const login = (username, type = null, profile = null) => {
    setUserEmail(username);
    localStorage.setItem("username", username);

    if (type) {
      setUserType(type);
      localStorage.setItem("userType", type);
    }

    if (profile) {
      setUserProfile(profile);
      localStorage.setItem("userProfile", JSON.stringify(profile));
    }

    setIsLoggedIn(true);
  };

  const logout = async () => {
    try {
      const token = localStorage.getItem("authToken");

      if (token) {
        await fetch(`/api.php?action=logout`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
    } catch (error) {
      console.error("Logout error:", error);
    } finally {
      localStorage.removeItem("authToken");
      localStorage.removeItem("userId");
      localStorage.removeItem("username");
      localStorage.removeItem("userType");
      localStorage.removeItem("userProfile");

      setIsLoggedIn(false);
      setUserEmail("");
      setUserId(null);
      setUserType(null);
      setUserProfile(null);
    }
  };

  const isVendor = () => userType === "vendor";
  const isClient = () => userType === "client";

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        setIsLoggedIn,
        userEmail,
        userId,
        userType,
        userProfile,
        login,
        logout,
        isLoading,
        isVendor,
        isClient,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
