// import React, { useState } from "react";
import React from "react";
import PageTransition from "../pagetransition.js";
// import LoginPage from "../loginpage.js";
import "./home.css";

const HomePage = () => {
  // const [isAuthenticated, setIsAuthenticated] = useState(false);

  // if (!isAuthenticated) {
  //   return <LoginPage onLogin={setIsAuthenticated} />;
  // }

  return (
    <PageTransition>
      <div className="container">
        <div className="home-wrapper">
          <img
            src="/gsflogo.png"
            alt="Global Exchange"
            className="home-image"
          />
        </div>
      </div>
    </PageTransition>
  );
};

export default HomePage;
