import React, { useState, useEffect } from "react";
import availableServices from "../availableservices/availableservices.js";

const ServiceFilter = ({ onFilterChange }) => {
  const [selectedServices, setSelectedServices] = useState([]);
  const [matchingMode, setMatchingMode] = useState("OR");

  const toggleService = (service) => {
    const updatedServices = [...selectedServices];
    const serviceIndex = updatedServices.indexOf(service);

    if (serviceIndex === -1) {
      updatedServices.push(service);
    } else {
      updatedServices.splice(serviceIndex, 1);
    }

    setSelectedServices(updatedServices);
  };

  const toggleMatchingMode = () => {
    setMatchingMode((prevMode) => (prevMode === "OR" ? "AND" : "OR"));
  };

  const clearAllFilters = () => {
    setSelectedServices([]);
    setMatchingMode("OR");

    onFilterChange({
      services: [],
      matchingMode: "OR",
    });
  };

  useEffect(() => {
    onFilterChange({
      services: selectedServices,
      matchingMode: matchingMode,
    });
  }, [selectedServices, matchingMode, onFilterChange]);

  return (
    <div className="service-filter">
      <div className="filter-header">
        <h3>Filter by Services</h3>
        <button
          className="clear-filters-button"
          onClick={clearAllFilters}
          disabled={selectedServices.length === 0}
        >
          Clear All Filters
        </button>
      </div>

      <div className="matching-mode-toggle">
        <span className="matching-mode-label">Matching Mode:</span>
        <div
          className={`toggle-switch ${
            matchingMode === "AND" ? "and-mode" : "or-mode"
          }`}
          onClick={toggleMatchingMode}
        >
          <div className="toggle-indicator"></div>
          <span className={`or-label ${matchingMode === "OR" ? "active" : ""}`}>
            Match Any Selection (OR)
          </span>
          <span
            className={`and-label ${matchingMode === "AND" ? "active" : ""}`}
          >
            Match All Selections (AND)
          </span>
        </div>
      </div>

      <div className="services-container">
        {availableServices.map((service) => (
          <div
            key={service}
            className={`service-tag ${
              selectedServices.includes(service) ? "selected" : ""
            }`}
            onClick={() => toggleService(service)}
            role="checkbox"
            aria-checked={selectedServices.includes(service)}
            tabIndex={0}
            onKeyPress={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                toggleService(service);
                e.preventDefault();
              }
            }}
          >
            {service}
          </div>
        ))}
      </div>

      {selectedServices.length > 0 && (
        <div className="active-filters">
          <p>Active Filters: {selectedServices.join(", ")}</p>
          <p>
            Mode:{" "}
            {matchingMode === "OR"
              ? "Match Any Selection"
              : "Match All Selections"}
          </p>
        </div>
      )}
    </div>
  );
};

export default ServiceFilter;
