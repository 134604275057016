export const apiRequest = async (action, method = "GET", data = null) => {
  const baseUrl =
    window.location.hostname === "localhost"
      ? "http://localhost:8000/api.php"
      : "https://globalservices.site/api.php";

  const apiUrl = `${baseUrl}?action=${action}`;
  const authToken = localStorage.getItem("authToken");

  const headers = {
    "Content-Type": "application/json",
  };

  if (authToken) {
    headers["Authorization"] = `Bearer ${authToken}`;
  }

  const options = {
    method,
    headers,
    credentials: "include",
  };

  if (method !== "GET" && data) {
    options.body = JSON.stringify(data);
  }

  try {
    console.log(`Sending ${method} request to ${apiUrl}`, data);
    const response = await fetch(apiUrl, options);

    // First check for HTTP errors
    if (!response.ok) {
      const errorText = await response.text();
      console.error(`HTTP Error ${response.status}: ${errorText}`);

      if (response.status === 401) {
        localStorage.removeItem("authToken");
        localStorage.removeItem("userId");
        localStorage.removeItem("username");
        window.location.href = "/login";
      }

      // Try to parse as JSON, but fall back to plain text if needed
      try {
        const errorJson = JSON.parse(errorText);
        throw new Error(
          errorJson.error || `Request failed with status ${response.status}`
        );
      } catch (e) {
        throw new Error(
          `Server error (${response.status}): ${errorText || "Unknown error"}`
        );
      }
    }

    // Now try to parse JSON
    try {
      const contentType = response.headers.get("content-type");
      if (!contentType || !contentType.includes("application/json")) {
        const textResponse = await response.text();
        console.error("Non-JSON response:", textResponse.substring(0, 500));
        throw new Error(`Server error: Non-JSON response`);
      }

      const responseData = await response.json();
      return responseData;
    } catch (jsonError) {
      console.error("JSON Parsing Error:", jsonError);
      throw new Error(`Failed to parse server response: ${jsonError.message}`);
    }
  } catch (error) {
    console.error(`API Error (${action}):`, error);
    throw error;
  }
};
