import { Route, Routes } from "react-router-dom";
import HomePage from "./home/home.js";
import Header from "./header/header";
import Login from "./auth/login.js";
import Register from "./auth/register.js";
import Settings from "./settings/settings.js";
import { AuthProvider } from "./authcontext.js";
import Listings from "./listings/listings.js";
import Vendors from "./vendors/vendors.js";

function App() {
  return (
    <AuthProvider>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="listings" element={<Listings />} />
          <Route path="vendors" element={<Vendors />} />
          <Route path="settings" element={<Settings />} />
        </Routes>
      </div>
    </AuthProvider>
  );
}

export default App;
