import React, { useState, useEffect, useCallback } from "react";
import { useAuth } from "../authcontext.js";
import { CountrySelector } from "../countryselector/countryselector";
import { apiRequest } from "../apiservice.js";
import availableServices from "../availableservices/availableservices";
import "./listings.css";

const Listings = () => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [currentEditListing, setCurrentEditListing] = useState(null);
  const [listings, setListings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [contactName, setContactName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactPhone, setContactPhone] = useState("");

  const { isLoggedIn, userType } = useAuth();

  const apiBaseUrl = process.env.REACT_APP_API_URL;

  console.log("Current auth state:", { isLoggedIn, userType });

  const fetchVendorProfile = useCallback(async () => {
    try {
      const userId = localStorage.getItem("userId");
      if (!userId) {
        setError("You must be logged in to view your listings");
        setLoading(false);
        return;
      }

      const data = await apiRequest(`settings&userId=${userId}`, "GET");

      if (data.profile) {
        setCompanyName(data.profile.company_name || "");
        setContactName(data.profile.contact_name || "");
        setContactEmail(data.profile.contact_email || "");
        setContactPhone(data.profile.contact_phone || "");
      }
    } catch (err) {
      console.error("Error fetching vendor profile:", err);
    }
  }, []);

  const fetchListings = useCallback(async () => {
    try {
      setLoading(true);

      const userId = localStorage.getItem("userId");
      const authToken = localStorage.getItem("authToken");

      console.log("Fetching listings for user:", userId);

      if (!userId || !authToken) {
        setError("You must be logged in to view your listings");
        setLoading(false);
        return;
      }

      const response = await fetch(
        `${apiBaseUrl}/api.php?action=getListings&userId=${userId}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      const data = await response.json();
      console.log("Listings data:", data);

      if (response.ok) {
        setListings(data.listings || []);
      } else {
        setError(data.error || "Failed to fetch listings");
      }
    } catch (err) {
      console.error("Error fetching listings:", err);
      setError("Error fetching listings. Please try again.");
    } finally {
      setLoading(false);
    }
  }, [apiBaseUrl]);

  useEffect(() => {
    const storedUserType = localStorage.getItem("userType");
    console.log("Stored user type:", storedUserType);

    if (isLoggedIn && (userType === "vendor" || storedUserType === "vendor")) {
      console.log("User is a vendor, fetching data");
      fetchVendorProfile();
      fetchListings();
    } else {
      console.log("User is not a vendor or not logged in");
      setLoading(false);
    }
  }, [isLoggedIn, userType, fetchVendorProfile, fetchListings]);

  const deleteListing = async (listingId) => {
    if (!window.confirm("Are you sure you want to delete this listing?")) {
      return;
    }

    try {
      const userId = localStorage.getItem("userId");
      const authToken = localStorage.getItem("authToken");

      const response = await fetch(
        `${apiBaseUrl}/api.php?action=deleteListing`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify({
            userId: userId,
            listingId: listingId,
          }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        setSuccess("Listing deleted successfully");
        setListings(listings.filter((listing) => listing.id !== listingId));
        setTimeout(() => {
          setSuccess("");
        }, 3000);
      } else {
        setError(data.error || "Failed to delete listing");
      }
    } catch (err) {
      setError("Error deleting listing. Please try again.");
    }
  };

  const handleEditListing = (listing) => {
    setCurrentEditListing(listing);
    setShowEditModal(true);
  };

  const CreateListingModal = () => {
    const [listingData, setListingData] = useState({
      country_of_operation: "",
      vendor_services: [],
      website: "",
      phone: "",
      email: "",
      address1: "",
      address2: "",
      description: "",
    });
    const [submitting, setSubmitting] = useState(false);
    const [modalError, setModalError] = useState("");

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setListingData({
        ...listingData,
        [name]: value,
      });
    };

    const toggleService = (service) => {
      const updatedServices = [...listingData.vendor_services];
      const serviceIndex = updatedServices.indexOf(service);

      if (serviceIndex === -1) {
        updatedServices.push(service);
      } else {
        updatedServices.splice(serviceIndex, 1);
      }

      setListingData({
        ...listingData,
        vendor_services: updatedServices,
      });
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      setModalError("");
      setSubmitting(true);

      if (
        !listingData.country_of_operation ||
        listingData.vendor_services.length === 0
      ) {
        setModalError(
          "Country of operation and at least one service are required"
        );
        setSubmitting(false);
        return;
      }

      try {
        const userId = localStorage.getItem("userId");
        const authToken = localStorage.getItem("authToken");

        if (!userId || !authToken) {
          setModalError("You must be logged in to create a listing");
          setSubmitting(false);
          return;
        }

        const payload = {
          ...listingData,
          company_name: companyName,
          userId: userId,
        };

        const response = await fetch(
          `${apiBaseUrl}/api.php?action=createListing`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
            body: JSON.stringify(payload),
          }
        );

        const data = await response.json();

        if (response.ok) {
          setSuccess("Listing created successfully");

          if (data.listingId) {
            const newListing = {
              id: data.listingId,
              ...payload,
              created_at: new Date().toISOString(),
            };
            setListings([...listings, newListing]);
          }

          setShowCreateModal(false);

          setTimeout(() => {
            setSuccess("");
          }, 3000);
        } else {
          setModalError(data.error || "Failed to create listing");
        }
      } catch (err) {
        setModalError("Error creating listing. Please try again.");
      } finally {
        setSubmitting(false);
      }
    };

    return (
      <div className="modal-overlay">
        <div className="modal-content">
          <div className="modal-header">
            <h3>Create New Listing</h3>
            <button
              className="close-btn"
              onClick={() => setShowCreateModal(false)}
            >
              ×
            </button>
          </div>
          <div className="modal-body">
            {modalError && <div className="error-message">{modalError}</div>}

            <form onSubmit={handleSubmit} className="listing-form">
              <div className="company-info">
                <h4>Company Information</h4>
                <p>
                  Company Name: <strong>{companyName}</strong>
                </p>
                <p>
                  Contact Name: <strong>{contactName}</strong>
                </p>
                <p>
                  Contact Email: <strong>{contactEmail}</strong>
                </p>
                <p>
                  Contact Phone: <strong>{contactPhone}</strong>
                </p>
              </div>

              <h4>Required Information</h4>
              <div className="form-group">
                <label>Country of Operation*</label>
                <CountrySelector
                  value={listingData.country_of_operation}
                  onChange={(country) =>
                    setListingData({
                      ...listingData,
                      country_of_operation: country,
                    })
                  }
                  required
                />
              </div>

              <div className="form-group">
                <label>Services Offered* (select at least one)</label>
                <p className="selection-hint">
                  Click on the services your company provides
                </p>
                <div className="services-container">
                  {availableServices.map((service) => (
                    <div
                      key={service}
                      className={`service-tag ${
                        listingData.vendor_services.includes(service)
                          ? "selected"
                          : ""
                      }`}
                      onClick={() => toggleService(service)}
                      role="checkbox"
                      aria-checked={listingData.vendor_services.includes(
                        service
                      )}
                      tabIndex={0}
                      onKeyPress={(e) => {
                        if (e.key === "Enter" || e.key === " ") {
                          toggleService(service);
                          e.preventDefault();
                        }
                      }}
                    >
                      {service}
                    </div>
                  ))}
                </div>
                {listingData.vendor_services.length === 0 && (
                  <div className="selection-error">
                    Please select at least one service
                  </div>
                )}
              </div>

              <h4>Optional Information</h4>
              <div className="form-group">
                <label>Website</label>
                <input
                  type="text"
                  name="website"
                  value={listingData.website}
                  onChange={handleInputChange}
                  placeholder="website.com"
                />
              </div>

              <div className="form-group">
                <label>Phone Number</label>
                <input
                  type="tel"
                  name="phone"
                  value={listingData.phone}
                  onChange={handleInputChange}
                />
              </div>

              <div className="form-group">
                <label>Email Address</label>
                <input
                  type="email"
                  name="email"
                  value={listingData.email}
                  onChange={handleInputChange}
                />
              </div>

              <div className="form-group">
                <label>Address Line 1</label>
                <input
                  type="text"
                  name="address1"
                  value={listingData.address1}
                  onChange={handleInputChange}
                />
              </div>

              <div className="form-group">
                <label>Address Line 2</label>
                <input
                  type="text"
                  name="address2"
                  value={listingData.address2}
                  onChange={handleInputChange}
                />
              </div>

              <div className="form-group">
                <label>Description</label>
                <textarea
                  name="description"
                  value={listingData.description}
                  onChange={handleInputChange}
                  rows="4"
                ></textarea>
              </div>

              <div className="button-group">
                <button
                  type="button"
                  className="cancel-button"
                  onClick={() => setShowCreateModal(false)}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="create-button"
                  disabled={submitting}
                >
                  {submitting ? "Creating..." : "Create Listing"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  };

  const EditListingModal = () => {
    const [listingData, setListingData] = useState({
      country_of_operation: currentEditListing.country_of_operation || "",
      vendor_services: Array.isArray(currentEditListing.vendor_services)
        ? currentEditListing.vendor_services
        : [currentEditListing.vendor_services],
      website: currentEditListing.website || "",
      phone: currentEditListing.phone || "",
      email: currentEditListing.email || "",
      address1: currentEditListing.address1 || "",
      address2: currentEditListing.address2 || "",
      description: currentEditListing.description || "",
    });
    const [submitting, setSubmitting] = useState(false);
    const [modalError, setModalError] = useState("");

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setListingData({
        ...listingData,
        [name]: value,
      });
    };

    const toggleService = (service) => {
      const updatedServices = [...listingData.vendor_services];
      const serviceIndex = updatedServices.indexOf(service);

      if (serviceIndex === -1) {
        updatedServices.push(service);
      } else {
        updatedServices.splice(serviceIndex, 1);
      }

      setListingData({
        ...listingData,
        vendor_services: updatedServices,
      });
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      setModalError("");
      setSubmitting(true);

      if (listingData.vendor_services.length === 0) {
        setModalError("At least one service is required");
        setSubmitting(false);
        return;
      }

      try {
        const userId = localStorage.getItem("userId");
        const authToken = localStorage.getItem("authToken");

        if (!userId || !authToken) {
          setModalError("You must be logged in to update a listing");
          setSubmitting(false);
          return;
        }

        const payload = {
          ...listingData,
          company_name: companyName,
          userId: userId,
          listingId: currentEditListing.id,
          country_of_operation: currentEditListing.country_of_operation,
        };

        const response = await fetch(
          `${apiBaseUrl}/api.php?action=updateListings`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
            body: JSON.stringify(payload),
          }
        );

        const data = await response.json();

        if (response.ok) {
          setSuccess("Listing updated successfully");
          setListings(
            listings.map((listing) =>
              listing.id === currentEditListing.id
                ? {
                    ...listing,
                    ...listingData,
                    country_of_operation:
                      currentEditListing.country_of_operation,
                  }
                : listing
            )
          );

          setShowEditModal(false);
          setCurrentEditListing(null);

          setTimeout(() => {
            setSuccess("");
          }, 3000);
        } else {
          setModalError(data.error || "Failed to update listing");
        }
      } catch (err) {
        setModalError("Error updating listing. Please try again.");
      } finally {
        setSubmitting(false);
      }
    };

    return (
      <div className="modal-overlay">
        <div className="modal-content">
          <div className="modal-header">
            <h3>Edit Listing</h3>
            <button
              className="close-btn"
              onClick={() => {
                setShowEditModal(false);
                setCurrentEditListing(null);
              }}
            >
              ×
            </button>
          </div>
          <div className="modal-body">
            {modalError && <div className="error-message">{modalError}</div>}

            <form onSubmit={handleSubmit} className="listing-form">
              <div className="company-info">
                <h4>Company Information</h4>
                <p>
                  Company Name: <strong>{companyName}</strong>
                </p>
                <p>
                  Contact Name: <strong>{contactName}</strong>
                </p>
                <p>
                  Contact Email: <strong>{contactEmail}</strong>
                </p>
                <p>
                  Contact Phone: <strong>{contactPhone}</strong>
                </p>
              </div>

              <h4>Required Information</h4>
              <div className="form-group">
                <label>Country of Operation</label>
                <div className="readonly-field-container">
                  <span className="readonly-field">
                    {currentEditListing.country_of_operation}
                  </span>
                </div>
              </div>

              <div className="form-group">
                <label>Services Offered* (select at least one)</label>
                <p className="selection-hint">
                  Click on the services your company provides
                </p>
                <div className="services-container">
                  {availableServices.map((service) => (
                    <div
                      key={service}
                      className={`service-tag ${
                        listingData.vendor_services.includes(service)
                          ? "selected"
                          : ""
                      }`}
                      onClick={() => toggleService(service)}
                      role="checkbox"
                      aria-checked={listingData.vendor_services.includes(
                        service
                      )}
                      tabIndex={0}
                      onKeyPress={(e) => {
                        if (e.key === "Enter" || e.key === " ") {
                          toggleService(service);
                          e.preventDefault();
                        }
                      }}
                    >
                      {service}
                    </div>
                  ))}
                </div>
                {listingData.vendor_services.length === 0 && (
                  <div className="selection-error">
                    Please select at least one service
                  </div>
                )}
              </div>

              <h4>Optional Information</h4>
              <div className="form-group">
                <label>Website</label>
                <input
                  type="text"
                  name="website"
                  value={listingData.website}
                  onChange={handleInputChange}
                  placeholder="website.com"
                />
              </div>

              <div className="form-group">
                <label>Phone Number</label>
                <input
                  type="tel"
                  name="phone"
                  value={listingData.phone}
                  onChange={handleInputChange}
                />
              </div>

              <div className="form-group">
                <label>Email Address</label>
                <input
                  type="email"
                  name="email"
                  value={listingData.email}
                  onChange={handleInputChange}
                />
              </div>

              <div className="form-group">
                <label>Address Line 1</label>
                <input
                  type="text"
                  name="address1"
                  value={listingData.address1}
                  onChange={handleInputChange}
                />
              </div>

              <div className="form-group">
                <label>Address Line 2</label>
                <input
                  type="text"
                  name="address2"
                  value={listingData.address2}
                  onChange={handleInputChange}
                />
              </div>

              <div className="form-group">
                <label>Description</label>
                <textarea
                  name="description"
                  value={listingData.description}
                  onChange={handleInputChange}
                  rows="4"
                ></textarea>
              </div>

              <div className="button-group">
                <button
                  type="button"
                  className="cancel-button"
                  onClick={() => {
                    setShowEditModal(false);
                    setCurrentEditListing(null);
                  }}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="update-button"
                  disabled={submitting}
                >
                  {submitting ? "Updating..." : "Update Listing"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  };

  const ListingItem = ({ listing }) => {
    const [selectedService, setSelectedService] = useState(null);
    const toggleService = (service) => {
      if (selectedService === service) {
        setSelectedService(null);
      } else {
        setSelectedService(service);
      }
    };

    return (
      <div className="listing-item">
        <div className="listing-header">
          <h3>{companyName}</h3>
        </div>

        <div className="country-badge-container">
          <span className="country-badge">{listing.country_of_operation}</span>
        </div>

        <div className="listing-services">
          {Array.isArray(listing.vendor_services) ? (
            listing.vendor_services.map((service) => (
              <span
                key={service}
                className={`service-badge clickable ${
                  selectedService === service ? "selected" : ""
                }`}
                onClick={() => toggleService(service)}
                tabIndex={0}
                role="button"
                onKeyPress={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    toggleService(service);
                    e.preventDefault();
                  }
                }}
              >
                {service}
              </span>
            ))
          ) : (
            <span
              className="service-badge clickable"
              onClick={() => toggleService(listing.vendor_services)}
              tabIndex={0}
              role="button"
              onKeyPress={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  toggleService(listing.vendor_services);
                  e.preventDefault();
                }
              }}
            >
              {listing.vendor_services}
            </span>
          )}
        </div>

        <div className="listing-details">
          {listing.website && (
            <p>
              <strong>Website:</strong> {listing.website}
            </p>
          )}
          {listing.phone && (
            <p>
              <strong>Phone:</strong> {listing.phone}
            </p>
          )}
          {listing.email && (
            <p>
              <strong>Email:</strong> {listing.email}
            </p>
          )}
          {listing.address1 && (
            <p>
              <strong>Address:</strong> {listing.address1}
            </p>
          )}
          {listing.address2 && <p>{listing.address2}</p>}
          {listing.description && (
            <p className="listing-description">{listing.description}</p>
          )}
        </div>

        <div className="listing-actions">
          <button
            className="edit-button"
            onClick={() => handleEditListing(listing)}
          >
            Edit Listing
          </button>
          <button
            className="delete-button"
            onClick={() => deleteListing(listing.id)}
          >
            Delete Listing
          </button>
        </div>
      </div>
    );
  };

  const storedUserType = localStorage.getItem("userType");
  const isVendor = userType === "vendor" || storedUserType === "vendor";

  if (!isVendor) {
    return (
      <div className="listings-container">
        <h1>Vendor Listings</h1>
        <p className="info-message">
          Only vendor accounts can create and manage listings. If you are a
          security provider and want to list your services, please register for
          a vendor account.
        </p>
      </div>
    );
  }

  return (
    <div className="listings-container">
      <h1>Vendor Listings</h1>

      {error && <div className="error-message">{error}</div>}
      {success && <div className="success-message">{success}</div>}

      <div className="listings-header">
        <button
          className="create-listing-button"
          onClick={() => setShowCreateModal(true)}
        >
          Create New Listing
        </button>
      </div>

      <div className="listings-section">
        <h2>Your Live Listings</h2>
        {loading ? (
          <p>Loading your listings...</p>
        ) : listings.length > 0 ? (
          <div className="listings-grid">
            {listings.map((listing) => (
              <ListingItem key={listing.id} listing={listing} />
            ))}
          </div>
        ) : (
          <p className="no-listings">
            You haven't created any listings yet. Click the "Create New Listing"
            button to get started.
          </p>
        )}
      </div>

      {showCreateModal && <CreateListingModal />}
      {showEditModal && currentEditListing && <EditListingModal />}
    </div>
  );
};

export default Listings;
