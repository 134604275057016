import React, { useState, useEffect, useCallback } from "react";
import { useAuth } from "../authcontext.js";
import "./settings.css";

const Settings = () => {
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [username, setUsername] = useState("");
  const [userType, setUserType] = useState("");
  const [loading, setLoading] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [contactName, setContactName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [preferences, setPreferences] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const {
    userEmail: contextUserEmail,
    userType: contextUserType,
    userProfile: contextUserProfile,
  } = useAuth();

  const fetchUserData = useCallback(async () => {
    try {
      if (contextUserEmail) {
        setUsername(contextUserEmail);
      }

      const storedUserType =
        contextUserType || localStorage.getItem("userType");
      if (storedUserType) {
        setUserType(storedUserType);
      }

      if (contextUserProfile) {
        populateProfileFields(contextUserProfile, storedUserType);
        return;
      }

      const userId = localStorage.getItem("userId");
      const authToken = localStorage.getItem("authToken");

      if (!userId || !authToken) {
        setError("You must be logged in to view settings");
        return;
      }

      const response = await fetch(
        `/api.php?action=settings&userId=${userId}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      const data = await response.json();

      if (response.ok) {
        setUsername(data.username);
        if (data.user_type) {
          setUserType(data.user_type);
        }
        if (data.profile) {
          populateProfileFields(data.profile, data.user_type);
        }
      } else {
        setError(data.error || "Failed to fetch user data");
      }
    } catch (err) {
      setError("Failed to fetch user data");
    }
  }, [contextUserEmail, contextUserType, contextUserProfile]);

  const populateProfileFields = (profileData, type) => {
    if (!profileData) return;

    if (type === "vendor") {
      setCompanyName(profileData.company_name || "");
      setContactPhone(profileData.contact_phone || "");
      setContactName(profileData.contact_name || "");
      setContactEmail(profileData.contact_email || "");
    } else if (type === "client") {
      setFirstName(profileData.first_name || "");
      setLastName(profileData.last_name || "");
      setPreferences(profileData.preferences || "");
    }
  };

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");
    setLoading(true);

    if (newPassword !== confirmPassword) {
      setError("New passwords do not match");
      setLoading(false);
      return;
    }

    if (newPassword.length < 6) {
      setError("Password must be at least 6 characters long");
      setLoading(false);
      return;
    }

    try {
      const userId = localStorage.getItem("userId");
      const authToken = localStorage.getItem("authToken");

      if (!userId || !authToken) {
        setError("You must be logged in to change your password");
        setLoading(false);
        return;
      }

      const response = await fetch(`/api.php?action=settings`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          userId: userId,
          currentPassword,
          newPassword,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        setSuccess("Password updated successfully");
        setCurrentPassword("");
        setNewPassword("");
        setConfirmPassword("");
        setTimeout(() => {
          setShowPasswordModal(false);
          setSuccess("");
        }, 2000);
      } else {
        setError(data.error || "Failed to update password");
      }
    } catch (err) {
      setError("Failed to update password. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const ProfileModal = () => {
    const [localForm, setLocalForm] = useState({
      companyName: companyName,
      contactName: contactName,
      contactEmail: contactEmail,
      contactPhone: contactPhone,
      firstName: firstName,
      lastName: lastName,
      preferences: preferences,
    });

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setLocalForm({
        ...localForm,
        [name]: value,
      });
    };

    const handleSubmit = async (e) => {
      e.preventDefault();

      if (userType === "vendor") {
        setCompanyName(localForm.companyName);
        setContactName(localForm.contactName);
        setContactEmail(localForm.contactEmail);
        setContactPhone(localForm.contactPhone);
      } else {
        setFirstName(localForm.firstName);
        setLastName(localForm.lastName);
        setPreferences(localForm.preferences);
      }

      setError("");
      setSuccess("");
      setLoading(true);

      try {
        const userId = localStorage.getItem("userId");
        const authToken = localStorage.getItem("authToken");

        if (!userId || !authToken) {
          setError("You must be logged in to update your profile");
          setLoading(false);
          return;
        }

        const profileData =
          userType === "vendor"
            ? {
                company_name: localForm.companyName,
                contact_phone: localForm.contactPhone,
                contact_name: localForm.contactName,
                contact_email: localForm.contactEmail,
              }
            : {
                first_name: localForm.firstName,
                last_name: localForm.lastName,
                preferences: localForm.preferences,
              };

        const response = await fetch(`/api.php?action=updateProfile`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify({
            userId: userId,
            userType: userType,
            profile: profileData,
          }),
        });

        const data = await response.json();

        if (response.ok) {
          setSuccess("Profile updated successfully");
          localStorage.setItem("userProfile", JSON.stringify(profileData));
          setTimeout(() => {
            setShowProfileModal(false);
            setSuccess("");
          }, 2000);
        } else {
          setError(data.error || "Failed to update profile");
        }
      } catch (err) {
        setError("Failed to update profile. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    return (
      <div className="modal-overlay">
        <div className="modal-content">
          <div className="modal-header">
            <h3>Update Profile Information</h3>
            <button
              className="close-btn"
              onClick={() => setShowProfileModal(false)}
            >
              ×
            </button>
          </div>
          <div className="modal-body">
            {error && <div className="error-message">{error}</div>}
            {success && <div className="success-message">{success}</div>}

            {userType === "vendor" ? (
              <form onSubmit={handleSubmit} className="profile-form">
                <div className="form-group">
                  <label>Company Name</label>
                  <input
                    type="text"
                    name="companyName"
                    value={localForm.companyName}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="form-group">
                  <label>Contact Name</label>
                  <input
                    type="text"
                    name="contactName"
                    value={localForm.contactName}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="form-group">
                  <label>Contact Email</label>
                  <input
                    type="email"
                    name="contactEmail"
                    value={localForm.contactEmail}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="form-group">
                  <label>Contact Phone</label>
                  <input
                    type="tel"
                    name="contactPhone"
                    value={localForm.contactPhone}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="button-group">
                  <button
                    type="button"
                    className="cancel-button"
                    onClick={() => setShowProfileModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="update-button"
                    disabled={loading}
                  >
                    {loading ? "Updating..." : "Update Profile"}
                  </button>
                </div>
              </form>
            ) : (
              <form onSubmit={handleSubmit} className="profile-form">
                <div className="form-group">
                  <label>First Name</label>
                  <input
                    type="text"
                    name="firstName"
                    value={localForm.firstName}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="form-group">
                  <label>Last Name</label>
                  <input
                    type="text"
                    name="lastName"
                    value={localForm.lastName}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="form-group">
                  <label>Preferences</label>
                  <textarea
                    name="preferences"
                    value={localForm.preferences}
                    onChange={handleInputChange}
                    rows="3"
                  ></textarea>
                </div>

                <div className="button-group">
                  <button
                    type="button"
                    className="cancel-button"
                    onClick={() => setShowProfileModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="update-button"
                    disabled={loading}
                  >
                    {loading ? "Updating..." : "Update Profile"}
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    );
  };

  const PasswordModal = () => (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <h3>Change Password</h3>
          <button
            className="close-btn"
            onClick={() => setShowPasswordModal(false)}
          >
            ×
          </button>
        </div>
        <div className="modal-body">
          {error && <div className="error-message">{error}</div>}
          {success && <div className="success-message">{success}</div>}

          <form onSubmit={handlePasswordChange} className="password-form">
            <div className="form-group">
              <label>Current Password</label>
              <input
                type="password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                required
              />
            </div>

            <div className="form-group">
              <label>New Password</label>
              <input
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
            </div>

            <div className="form-group">
              <label>Confirm New Password</label>
              <input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </div>

            <div className="button-group">
              <button
                type="button"
                className="cancel-button"
                onClick={() => setShowPasswordModal(false)}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="update-button"
                disabled={loading}
              >
                {loading ? "Updating..." : "Update Password"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );

  return (
    <div className="settings-container">
      <div className="settings-card">
        <h1 className="settings-title">Account Settings</h1>

        <div className="settings-section">
          <h3>Account Information</h3>
          <div className="account-info">
            <p>
              <strong>Username:</strong> {username}
            </p>
            <p>
              <strong>Account Type:</strong>{" "}
              {userType === "vendor" ? "Vendor" : "Client"}
            </p>

            {userType === "client" && (
              <>
                {firstName && (
                  <p>
                    <strong>First Name:</strong> {firstName}
                  </p>
                )}
                {lastName && (
                  <p>
                    <strong>Last Name:</strong> {lastName}
                  </p>
                )}
                {preferences && (
                  <p>
                    <strong>Preferences:</strong> {preferences}
                  </p>
                )}
              </>
            )}

            {userType === "vendor" && (
              <>
                {companyName && (
                  <p>
                    <strong>Company:</strong> {companyName}
                  </p>
                )}
                {contactName && (
                  <p>
                    <strong>Contact Name:</strong> {contactName}
                  </p>
                )}
                {contactEmail && (
                  <p>
                    <strong>Contact Email:</strong> {contactEmail}
                  </p>
                )}
                {contactPhone && (
                  <p>
                    <strong>Contact Phone:</strong> {contactPhone}
                  </p>
                )}
              </>
            )}
          </div>
        </div>

        <div className="settings-section">
          <div className="account-actions">
            <button
              className="action-button profile-button"
              onClick={() => setShowProfileModal(true)}
            >
              Update Profile Information
            </button>
            <button
              className="action-button password-button"
              onClick={() => setShowPasswordModal(true)}
            >
              Change Password
            </button>
          </div>
        </div>
      </div>

      {showProfileModal && <ProfileModal />}
      {showPasswordModal && <PasswordModal />}
    </div>
  );
};

export default Settings;
