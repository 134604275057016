const availableServices = [
  "Executive Protection",
  "Transportation",
  "Transportation Security Services",
  "Travel Risk Management",
  "Site Assessments",
  "Due Diligence",
  "Market Intelligence",
  "Intellectual Property Investigations",
  "Internal Investigations",
  "Fraud Investigations",
  "Linguistic Services",
  "Insurance Investigations",
  "Threat Assessments",
];

export default availableServices;
