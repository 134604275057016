import React from "react";
import { Link } from "react-router-dom";
import "./toggle.css";

const Toggle = ({ isOpen, toggleMenu, isLoggedIn, userEmail, onLogout }) => {
  const handleLogout = () => {
    onLogout();
    window.location.href = "/";
  };
  return (
    <div className="mobile-nav">
      <button
        className={`hamburger ${isOpen ? "open" : ""}`}
        onClick={toggleMenu}
      >
        <span></span>
        <span></span>
        <span></span>
      </button>

      <div className={`mobile-menu ${isOpen ? "open" : ""}`}>
        <div className="mobile-content">
          {isLoggedIn ? (
            <>
              <p>Account - {userEmail}</p>
              <Link to="/listings" onClick={toggleMenu}>
                My Listings
              </Link>
              <Link to="/settings" onClick={toggleMenu}>
                Settings
              </Link>
              <button onClick={handleLogout} className="mobile-link">
                Logout
              </button>
            </>
          ) : (
            <Link to="/login" onClick={toggleMenu} className="mobile-heading">
              Login
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default Toggle;
