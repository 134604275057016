import React, { useState, useEffect } from "react";

const countriesByRegion = {
  Africa: [
    "Angola",
    "Benin",
    "Botswana",
    "Burkina Faso",
    "Burundi",
    "Cameroon",
    "Cape Verde",
    "Central African Republic",
    "Chad",
    "Comoros",
    "Congo",
    "Côte d'Ivoire",
    "Djibouti",
    "Equatorial Guinea",
    "Eritrea",
    "Eswatini",
    "Ethiopia",
    "Gabon",
    "Gambia",
    "Ghana",
    "Guinea",
    "Guinea-Bissau",
    "Kenya",
    "Lesotho",
    "Liberia",
    "Madagascar",
    "Malawi",
    "Mali",
    "Mauritania",
    "Mauritius",
    "Mozambique",
    "Namibia",
    "Niger",
    "Nigeria",
    "Rwanda",
    "São Tomé and Príncipe",
    "Senegal",
    "Seychelles",
    "Sierra Leone",
    "Somalia",
    "South Africa",
    "South Sudan",
    "Tanzania",
    "Togo",
    "Uganda",
    "Zambia",
    "Zimbabwe",
  ].sort(),

  "Asia Pacific": [
    "Brunei",
    "Cambodia",
    "China",
    "Hong Kong",
    "Indonesia",
    "Japan",
    "Laos",
    "Macau",
    "Malaysia",
    "Mongolia",
    "Myanmar",
    "Philippines",
    "Singapore",
    "South Korea",
    "Taiwan",
    "Thailand",
    "Timor-Leste",
    "Vietnam",
  ].sort(),

  "Central America": [
    "Belize",
    "Costa Rica",
    "El Salvador",
    "Guatemala",
    "Honduras",
    "Nicaragua",
    "Panama",
  ].sort(),

  "Central Asia": [
    "Kazakhstan",
    "Kyrgyzstan",
    "Russia",
    "Tajikistan",
    "Turkmenistan",
    "Uzbekistan",
  ].sort(),

  Europe: [
    "Albania",
    "Andorra",
    "Austria",
    "Belgium",
    "Belarus",
    "Bosnia and Herzegovina",
    "Bulgaria",
    "Croatia",
    "Czech Republic",
    "Denmark",
    "Estonia",
    "Finland",
    "France",
    "Germany",
    "Greece",
    "Hungary",
    "Iceland",
    "Ireland",
    "Italy",
    "Latvia",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Malta",
    "Moldova",
    "Monaco",
    "Montenegro",
    "Netherlands",
    "North Macedonia",
    "Norway",
    "Poland",
    "Portugal",
    "Romania",
    "San Marino",
    "Serbia",
    "Slovakia",
    "Slovenia",
    "Spain",
    "Sweden",
    "Switzerland",
    "Ukraine",
    "United Kingdom",
    "Vatican City",
  ].sort(),

  "Middle East and North Africa": [
    "Algeria",
    "Bahrain",
    "Egypt",
    "Iran",
    "Iraq",
    "Israel",
    "Jordan",
    "Kuwait",
    "Lebanon",
    "Libya",
    "Morocco",
    "Oman",
    "Palestine",
    "Qatar",
    "Saudi Arabia",
    "Sudan",
    "Syria",
    "Tunisia",
    "United Arab Emirates",
    "Western Sahara",
    "Yemen",
  ].sort(),

  Oceania: [
    "Australia",
    "Fiji",
    "Kiribati",
    "Marshall Islands",
    "Micronesia",
    "Nauru",
    "New Zealand",
    "Palau",
    "Papua New Guinea",
    "Samoa",
    "Solomon Islands",
    "Tonga",
    "Tuvalu",
    "Vanuatu",
  ].sort(),

  "South America": [
    "Argentina",
    "Bolivia",
    "Brazil",
    "Chile",
    "Colombia",
    "Ecuador",
    "Guyana",
    "Paraguay",
    "Peru",
    "Suriname",
    "Uruguay",
    "Venezuela",
  ].sort(),

  "South Asia": [
    "Afghanistan",
    "Bangladesh",
    "Bhutan",
    "India",
    "Maldives",
    "Nepal",
    "Pakistan",
    "Sri Lanka",
  ].sort(),

  "North America": ["Canada", "Mexico", "United States"].sort(),
};

const regions = Object.keys(countriesByRegion).sort();

const CountrySelector = ({ value, onChange, required = false }) => {
  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(value || "");
  const [availableCountries, setAvailableCountries] = useState([]);

  useEffect(() => {
    if (value) {
      for (const region in countriesByRegion) {
        if (countriesByRegion[region].includes(value)) {
          setSelectedRegion(region);
          setAvailableCountries(countriesByRegion[region]);
          break;
        }
      }
    }
  }, [value]);

  const handleRegionChange = (e) => {
    const region = e.target.value;
    setSelectedRegion(region);

    if (region) {
      setAvailableCountries(countriesByRegion[region]);
      setSelectedCountry("");
      onChange("");
    } else {
      setAvailableCountries([]);
      setSelectedCountry("");
      onChange("");
    }
  };

  const handleCountryChange = (e) => {
    const country = e.target.value;
    setSelectedCountry(country);
    onChange(country);
  };

  return (
    <div className="country-selector">
      <div className="region-select form-group">
        <label>Region*</label>
        <select
          value={selectedRegion}
          onChange={handleRegionChange}
          required={required}
        >
          <option value="">Select a region</option>
          {regions.map((region) => (
            <option key={region} value={region}>
              {region}
            </option>
          ))}
        </select>
      </div>

      <div className="country-select form-group">
        <label>Country*</label>
        <select
          value={selectedCountry}
          onChange={handleCountryChange}
          disabled={!selectedRegion}
          required={required}
        >
          <option value="">Select a country</option>
          {availableCountries.map((country) => (
            <option key={country} value={country}>
              {country}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export { CountrySelector, countriesByRegion, regions };
